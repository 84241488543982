<template>
  <div>
    <div class="row">
      <div class="col-sm-12 bloque_titulo">Persona física</div>
    </div>

    <div class="row">
      <div class="col-sm-6 ">
        <div class="row item">
          <div class="col-sm-4 titulo">Score de buró</div>
          <div class="col-sm-8 contenido">{{ buro.score }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas abiertas</div>
          <div class="col-sm-8 contenido">{{ solicitud.data.comite.general.variablesUnykoo.TotalCuentasAbiertas }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas MOP 00</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_mop_00 }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas atrasos menores</div>
          <div class="col-sm-8 contenido">{{ solicitud.data.comite.general.variablesUnykoo.TotalCuentasAtrasoMenor }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas MOP 96</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_mop_96 }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas MOP 97</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_mop_97 }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Total créditos máximos pagos fijos</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(buro.total_creditos_maximos_pagos_fijos) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Total saldos actuales pagos fijos</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(buro.total_saldos_actuales_pagos_fijos) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Total saldos vencidos pagos fijos</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(buro.total_saldos_vencidos_pagos_fijos) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Total créditos máximos revolventes</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(buro.total_creditos_maximos_revolventes) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Total saldos actuales créditos revolventes</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(buro.total_saldos_actuales_revolventes) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Total saldos vencidos créditos revolventes</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(buro.total_saldos_vencidos_revolventes) }}</div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas cerradas</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_cerradas }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Total solicitudes reporte</div>
          <div class="col-sm-8 contenido">{{ buro.total_solicitudes_reporte }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas negativas actuales</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_negativas_actuales }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas claves historia negativa</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_negativas_actuales }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas claves historia negativa</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_claves_historia_negativa }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas disputadas</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_disputa }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Existencia delcaraciones consumidor</div>
          <div class="col-sm-8 contenido">{{ buro.existencia_declaraciones_consumidor }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas pagos fijo hipoteca</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_pagos_fijos_hipotecas }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas revolventes abiertas</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_revolventes_abiertas }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Fecha de ingreso BD</div>
          <div class="col-sm-8 contenido">{{ buro.fecha_ingreso_bd }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Fecha apertura de cuenta más antigua</div>
          <div class="col-sm-8 contenido">{{ buro.fecha_apertura_cuenta_mas_antigua }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Fecha apertura cuent más reciente</div>
          <div class="col-sm-8 contenido">{{ buro.fecha_apertura_cuenta_mas_reciente }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Nueva dirección (reportada en los últimos 60 días)</div>
          <div class="col-sm-8 contenido">{{ buro.nueva_direccion_reportada_ultimos_60_dias }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Fecha solicitud reporte más reciente</div>
          <div class="col-sm-8 contenido">{{ buro.fecha_solicitud_reporte_mas_reciente }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Mensaje de alerta</div>
          <div class="col-sm-8 contenido">{{ buro.mensaje }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas MOP 01</div>
          <div class="col-sm-8 contenido">{{ solicitud.data.comite.general.variablesUnykoo.TotalCuentasMop01 }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas MOP 02</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_mop_02 }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas MOP 03</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_mop_03 }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas MOP 04</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_mop_04 }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas MOP 06</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_mop_06 }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas MOP 07</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_mop_07 }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas MOP UR</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_mop_ur }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Cuentas MOP 99</div>
          <div class="col-sm-8 contenido">{{ buro.cuentas_mop_99 }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Número solicitudes en los últimos 6 meses</div>
          <div class="col-sm-8 contenido">{{ buro.numero_solicitudes_ultimos_6_meses }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Porcentaje límite de crédito utilizando revolventes</div>
          <div class="col-sm-8 contenido">{{ buro.pct_limite_credito_utilizado_revolventes }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Número total de cuentas despacho cobranza</div>
          <div class="col-sm-8 contenido">{{ buro.numero_total_solicitudes_despachos_cobranza }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Total pagos fijos</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(buro.total_pagos_pagos_fijos) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Total límites créditos revolventes</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(buro.total_limites_credito_revolventes) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Total pagos revolventes</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(buro.total_pagos_revolventes) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    solicitud: {
      type: Object,
      required: true
    },
    buro: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.bloque_titulo {
  background-color: #0781cd;
  color: #fefefe;
  font-size: 1.5rem;
  margin-top: 20px;
  padding: 2% 0 2% 6%;
}
.item {
  .titulo, .contenido {
    padding: 10px 15px;
  }

  .contenido {
    text-align: right;
  }
}

.item:nth-child(2n+1) {
  background-color: #e6e6e6;
}
</style>